<template>
  <div class="wrapper">
    <div class="logo" />
    <div class="login-left">
      <img src="../../../assets/images/login/login-left.png" alt="">
    </div>
    <div v-loading="loading" class="login">
      <p>欢迎登录</p>
      <h2>智慧养老平台</h2>
      <el-form ref="loginForm" :model="loginForm" :rules="rules">
        <el-form-item prop="name">
          <el-input v-model="loginForm.userName" prefix-icon="el-icon-user-solid" placeholder="请输入用户账户" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.pasword" type="password" prefix-icon="el-icon-lock" placeholder="请输入密码" />
        </el-form-item>
        <!-- <el-form-item prop="code">
        <el-row>
          <el-col :span="13">
            <el-input prefix-icon="el-icon-picture" v-model="loginForm.code" placeholder="验证码"></el-input>
          </el-col>
          <el-col :span="10" :offset="1">
            <div class="code">
              <img src="../../../assets/images/login/code.png" />
            </div>
          </el-col>
        </el-row>
      </el-form-item> -->
        <el-form-item />
        <el-form-item>
          <el-button type="primary" style="width: 100%" @click="onSubmit('loginForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <p>&copy; All Rights Reserved. 版权所有</p>
      <p>备案信息：<a target="_blank" href="https://beian.miit.gov.cn">陕ICP备2022011559号</a> </p>
      <p>技术支持：赵先生 &nbsp; 联系电话：15389282601</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  data() {
    return {
      loading: false,
      loginForm: {},
      rules: {
        userName: [
          {
            required: true,
            message: '请输入用户账户',
            trigger: 'blur'
          }
        ],
        passWord: [
          {
            required: true,
            message: '请输入用户密码',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    localStorage.clear()
    sessionStorage.clear()

  },
  methods: {
    ...mapMutations(['goToPage', 'removeTab']),
    ...mapActions(['loginServe', 'userInfo', 'authGetUserAuth']),
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.loginServe({ params: this.loginForm }).then((res) => {
            if (res.code !== 200) {
              this.loading = false
              this.$XModal.message({
                message: res.data,
                status: 'error'
              })
              return
            }
            // 存储Token
            sessionStorage.comId = res.data.compId
            sessionStorage.userId = res.data.id
            sessionStorage.compName = res.data.compName
            sessionStorage.token = res.data.token_type + ' ' + res.data.access_token
            localStorage.setItem('token', res.data.access_token)
            // 获取权限
            this.authGetUserAuth().then(res => {
              if (res.code !== 200) {
                this.loading = false
                this.$XModal.message({
                  message: res.msg,
                  status: 'error'
                })
                return
              }
              if (!res.data.length) {
                this.loading = false
                this.$XModal.message({ message: '您没有操作权限，请联系系统管理员', status: 'error' })
                return
              }
              this.formatUserAuth(res.data).then(roles => {
                sessionStorage.roles = JSON.stringify(roles)
                this.loading = false
                this.goToPage({ name: 'HomePage' })
              }).catch(() => {
                this.loading = false
                this.$XModal.message({ message: '获取权限失败', status: 'error' })
              })
            }).catch(() => {
              this.loading = false
              this.$XModal.message({ message: '获取权限失败', status: 'error' })
            })
            // this.getUserInfo()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    // 获取用户信息
    getUserInfo() {
      this.userInfo({
        params: {}
      }).then((res) => {
        if (res.code == 200) {
          if (res.message == 'successful') {
            let resources = res.data.resources
            if (!resources) {
              this.$XModal.message({
                message: '您没有操作权限，请联系系统管理员',
                status: 'error'
              })
              return
            } else {
              resources = resources.split(',')
            }
            console.log('sssss', res.data)
            console.log(res.data)
            this.$store.commit('mUserInfo', res.data)
            const arrs = ['Add', 'Edit', 'Remove', 'Export']
            if (resources.length) {
              for (const index in resources) {
                for (const arr of arrs) {
                  if (resources[index].indexOf(arr) > -1) {
                    resources.splice(index, 1)
                    break
                  }
                }
              }
            }
            if (resources[0]) {
              this.goToPage({
                name: resources[0]
              })
            } else {
              this.$XModal.message({
                message: '您没有权限登录系统，请联系管理员',
                status: 'error'
              })
            }
            // this.$router.replace({
            //   name: resources[0]
            // });
          }
        }
      })
    },
    formatUserAuth(authData) {
      return new Promise((resolve, reject) => {
        let roles = []
        const formatAuthFunc = data => {
          data.forEach(item => {
            if (item.status === 1) {
              roles.push(item.name)
              if (item.child && item.child.length > 0) {
                formatAuthFunc(item.child)
              }
            }
          })
        }
        formatAuthFunc(authData)
        if (roles.length > 0) {
          roles = roles.reduce((result, value) => {
            result[value] = value
            return result
          }, {})
          resolve(roles)
        } else {
          reject()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    height: 100%;
    min-height: 600px;
    background: url('../../../assets/images/login/login-bg.png') no-repeat top center / cover;
    position: relative;

    .logo {
      height: 100px;
      background: url('../../../assets/images/login/logo.png') no-repeat top left / 300px auto;
      display: none;
    }

    .login-left {
      position: absolute;
      top: 50%;
      left: 15%;
      height: 460px;
      margin-top: -324px;
    }

    .login {
      width: 360px;
      height: 460px;
      padding: 50px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 12px;
      position: absolute;
      top: 50%;
      right: 15%;
      margin-top: -254px;
      box-shadow: 8px 8px 5px #c2dffe;
      background: #f7f6fb;

      p {
        font-size: 16px;
        height: 40px;
        line-height: 40px;
        font-weight: 600;
        padding-bottom: 20px;
      }

      h2 {
        height: 40px;
        line-height: 40px;
        margin-bottom: 30px;
        color: #8bb9ff;
      }

      .code {
        height: 40px;
        overflow: hidden;

        img {
          height: 40px;
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 20px;
    width: 100%;

    p {
      text-align: center;
      color: #111;
      font-size: 14px;
    }
  }

  ::v-deep .el-input>.el-input__inner {
    border: 1px solid #8bb9ff;
  }
</style>
